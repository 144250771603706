import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';
import MathPage from './components/MathPage';
import SciencePage from './components/SciencePage';
import SoftwarePage from './components/SoftwarePage';
import ITPage from './components/ITPage';
import SubjectPage from './components/SubjectPage';
import './components/shared-styles.css';
import EduGen from './components/EduGen';
import Joblens from './components/JobLens';
import './App.css'

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // This function closes the menu when a link is clicked
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="app">
        {/* Modern and Responsive Navigation Bar */}
        <nav className="navbar">
          <div className="navbar-logo">
            <Link to="/" onClick={closeMenu}>EDU 2U</Link>
          </div>
          <div className="hamburger" onClick={toggleMenu}>
            {/* Hamburger icon for mobile */}
            ☰
          </div>
          <ul className={`navbar-links ${menuOpen ? 'open' : ''}`}>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/subject/math" onClick={closeMenu}>Math</Link></li>
            <li><Link to="/subject/science" onClick={closeMenu}>Science</Link></li>
            <li><Link to="/subject/software" onClick={closeMenu}>Software</Link></li>
            <li><Link to="/subject/it" onClick={closeMenu}>IT</Link></li>
          </ul>
        </nav>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/subject/math" element={<MathPage />} />
          <Route path="/subject/science" element={<SciencePage />} />
          <Route path="/subject/software" element={<SoftwarePage />} />
          <Route path="/subject/it" element={<ITPage />} />
          <Route path="/subject/:subjectKey" element={<SubjectPage />} />
          <Route path="/edugen" element={<EduGen />} />
          <Route path="/joblens" element={<Joblens />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
