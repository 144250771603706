import React from 'react';
import './HomePage.css';
import './shared-styles.css';
const HomePage = () => {
  return (
    <div className="homepage-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Welcome to EDU 2U</h1>
          <p>
            Empowering students to unlock their full potential through
            innovative, AI-driven learning experiences. At Edu 2U, we provide
            personalized resources designed to inspire curiosity, foster creativity,
            and develop critical thinking. From foundational subjects like Math and Science
            to advanced topics in Software Development and IT Infrastructure,
            we are committed to shaping the leaders and innovators of tomorrow. Explore your favorite ubjects now!

          </p>
        </div>
        <div className="hero-image">
          <img
            src="/images/learning-illustration.png"
            alt="Learning Illustration"
          />
        </div>
      </section>

      {/* Subjects Section */}
      <section className="subject-section">
        <div className="subject-grid">
          <div className="subject-card">
            <div className="subject-icon">🔢</div>
            <h3>Math</h3>
            <p>Master topics like Pre-Algebra, Algebra, Geometry, and more.</p>
            <a href="/subject/math" className="subject-link">Explore Math</a>
          </div>

          <div className="subject-card">
            <div className="subject-icon">🔬</div>
            <h3>Science</h3>
            <p>Unlock the secrets of Physics, Chemistry, Biology, and more.</p>
            <a href="/subject/science" className="subject-link">Explore Science</a>
          </div>

          <div className="subject-card">
            <div className="subject-icon">💻</div>
            <h3>Software Development</h3>
            <p>Get hands-on with coding, algorithms, and building applications.</p>
            <a href="/subject/software" className="subject-link">Explore Software</a>
          </div>

          <div className="subject-card">
            <div className="subject-icon">🖥️</div>
            <h3>IT & Infrastructure</h3>
            <p>Learn about networking, cloud, cybersecurity, and system administration.</p>
            <a href="/subject/it" className="subject-link">Explore IT</a>
          </div>
        </div>
      </section>
      <section className="subject-section">
        <div className="subject-grid">
          <div className="subject-card">
            <div className="subject-icon">📝</div> {/* Quiz Master Icon */}
            <h3>Ask Anything</h3>
            <p>
              Empower your learning with AI and create quizzes, lessons, and practice materials tailored to your subject topic, grade level, and difficulty.
            </p>
            <a href="/edugen" className="subject-link">Ask Anything</a>
          </div>

          <div className="subject-card">
            <div className="subject-icon">📄</div> {/* Resume vs JD Icon */}
            <h3>Resume - JD Matcher</h3>
            <p>
              Revolutionize your hiring workflow with AI-driven resume analysis that aligns candidates with job descriptions for optimal talent acquisition.
            </p>
            <a href="/joblens" className="subject-link">Resume vs JD</a>
          </div>
          <div className="subject-card">
            <div className="subject-icon"></div> {/* Resume vs JD Icon */}
          </div>

          <div className="subject-card">
            <div className="subject-icon"></div> {/* Resume vs JD Icon */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
